import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout';
import CallToAction from '../components/CallToAction';


const BranchenPage = ({ data, pageContext }) => {
  
  const offers = data.allMdx.nodes;

  return (
    <Layout title="Angebot" heroTitle="Die richtige Lösung" heroSubtitle="Für Ihren Bedarf" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="columns">
          <div className="column">
        
            <div className="block" >
              <div className="container">
                <h1 className="title">Unsere Branchen</h1>
                
                <div className="columns is-multiline is-mobile is-justify-content-center">
                
                {
                  offers.map((offer) => {
                    const backgroundImageStack = [
                      `radial-gradient(at 5% top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9))`
                    ];
                    const imageData = offer.frontmatter.hero_image.childImageSharp.gatsbyImageData;
                    if (imageData) backgroundImageStack.push(imageData);
                    
                    return (
                      <div className="column is-flex-grow-0 is-flex">
                        <Link to={`/${offer.slug}`} className="box">
                          <StaticImage src="../images/computer.svg" alt="Softwareentwicklung" layout="fullWidth" className="image is-128x128 mb-2"/>
                          <div className="content">
                            {offer.frontmatter.title}
                          </div>
                        </Link>
                      </div>
                    );
                  
                  })
                }
                
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
      <CallToAction title="Was können wir für Sie tun?" subtitle="Erfahren Sie mehr über uns und unsere Leistungen." />
      
    </Layout>
  );
}

export const query = graphql`query BranchenPage {
  heroImage: file(relativePath: {eq: "angebot.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    filter: {frontmatter: {templateKey: {eq: "branche-page"}}}
    sort: {fields: frontmatter___order}
  ) {
    nodes {
      frontmatter {
        title
        description
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        hero_image_alt
      }
      body
      slug
      excerpt(pruneLength: 100)
    }
  }
}
`

export default BranchenPage;
